export default function({ store, redirect, route}) {
  const user = store.state.user
  // console.log('checkin auth | user', user)
  if (user) {
    // if (route.query.ref) {
    //   redirect(route.query.ref)
    // } else {
    //   redirect(`/`)
    // }
    redirect(`/chat`)
  }
}
